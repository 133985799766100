<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>参数配置列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card v-if="isShowData">
      <!-- 查询 -->
      <div
        style="
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        "
      >
        <label style="margin: 10px" for=""
          >参数类型：
          <el-select
            v-model="query.type"
            clearable
            size="small"
            placeholder="请选择参数类型"
          >
            <el-option
              v-for="(item, index) in type"
              :key="index"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </label>
        <el-button
          style="margin-left: 10px"
          type="primary"
          size="small"
          @click="sysConfigParamListcx"
          >查询</el-button
        >
        <el-button size="small" @click="sysConfigParamListcz()">重置</el-button>
      </div>
      <!-- 点击添加显示对话框 -->
      <el-button
        type="primary"
        size="small "
        icon="el-icon-plus"
        @click="isShowAdd = true"
        >新增</el-button
      >
      <hr />
      <!-- 表格 -->
      <transition appear tag="div">
        <el-table
          v-loading="loading"
          ref="multipleTable"
          :data="tableData"
          stripe
          tooltip-effect="dark"
          style="width: 100%"
        >
          <el-table-column
            header-align="left"
            align="left"
            prop="id"
            label="编号"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="type"
            label="参数类型"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="value"
            label="参数内容"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="createTime"
            label="创建时间"
          >
            <template slot-scope="scope">
              <span>{{ conversionTime(scope.row.createTime) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="modifyTime"
            label="更新时间"
          >
            <template slot-scope="scope">
              <span>{{ conversionTime(scope.row.modifyTime) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            label="操作"
            width="120"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="isShoweditDialog(scope)"
                size="mini"
                icon="el-icon-edit"
                >编辑</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </transition>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.current"
        :page-sizes="[10, 50, 100]"
        :page-size="query.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 添加 -->
    <el-dialog
      title="新建参数配置"
      :visible.sync="isShowAdd"
      @close="addClose"
      width="40%"
      v-el-drag-dialog
    >
      <el-form
        :model="addForm"
        :rules="formRules"
        ref="addRef"
        label-width="150px"
        class="demo-ruleForm"
      >
        <el-form-item label="参数类型" prop="type">
          <el-select
            v-model="addForm.type"
            clearable
            size="small"
            placeholder="请选择参数类型"
          >
            <el-option
              v-for="(item, index) in type"
              :key="index"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="参数内容" prop="value">
          <el-input
            v-model="addForm.value"
            type="textarea"
            placeholder="请输入参数内容"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShowAdd = false">取 消</el-button>
        <el-button type="primary" @click="addClick">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑 -->
    <el-dialog
      title="编辑参数配置"
      :visible.sync="isShowEdit"
      width="40%"
      v-el-drag-dialog
    >
      <el-form
        :model="editForm"
        :rules="formRules"
        ref="editRef"
        label-width="150px"
        class="demo-ruleForm"
      >
        <el-form-item label="参数类型" prop="type">
          <el-select
            v-model="editForm.type"
            clearable
            size="small"
            placeholder="请选择参数类型"
          >
            <el-option
              v-for="(item, index) in type"
              :key="index"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="参数内容" prop="value">
          <el-input
            v-model="editForm.value"
            type="textarea"
            placeholder="请输入参数内容"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShowEdit = false">取 消</el-button>
        <el-button type="primary" @click="editClick">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { sysConfigParamList, addsysConfigParam } from '@/api/dictionaries.js'
import { sysDictData } from '@/api/dictionaries.js'
export default {
  data() {
    return {
      loading: false,
      isShowData: false,
      isShowQuery: false,
      tableData: [],
      isShowAdd: false,
      isShowEdit: false,
      query: {
        // 查询条件
        current: 1,
        size: 10,
        type: undefined, // 类型
      },
      total: undefined,
      formRules: {
        // 添加表单的验证规则
        username: [
          { required: true, message: '请输入登入', trigger: 'blur' },
          {
            min: 1,
            max: 99,
            message: '长度在 1 到 99 个字符',
            trigger: 'blur',
          },
        ],
      },
      addForm: {
        // 添加
        id: undefined,
        value: undefined,
        type: undefined,
      },
      editForm: {
        // 添加
        id: undefined,
        value: undefined,
        type: undefined,
      },
      type: [],
    }
  },
  created() {
    this.sysDictData()
  },
  methods: {
    sysDictData() {
      // 字典
      sysDictData({ typeId: 90 }).then((res) => {
        // 系统配置类型
        this.type = res.data.data
        this.sysConfigParamList()
      })
    },
    sysConfigParamList() {
      // 列表
      sysConfigParamList(this.query).then((res) => {
        this.tableData = res.data.data.list
        this.total = res.data.data.total
        this.isShowData = true
        this.loading = false
      })
    },
    sysConfigParamListcx() {
      // 查询
      this.query.current = 1
      this.query.size = 10
      this.sysConfigParamList()
    },
    sysConfigParamListcz() {
      // 重置
      this.query.current = 1
      this.query.size = 10
      this.query.type = undefined 
      this.sysConfigParamList()
    },
    handleSizeChange(val) {
      this.loading = true
      this.query.size = val
      this.sysConfigParamList()
    },
    handleCurrentChange(val) {
      this.loading = true
      this.query.current = val
      this.sysConfigParamList()
    },
    addClose() {
      // 监听添加用户对话框的关闭事件 清空input数据
      this.$refs.addRef.resetFields()
    },
    addClick() {
      // 添加
      this.$refs.addRef.validate((valid) => {
        if (!valid) return
        addsysConfigParam(this.addForm).then((res) => {
          if (res.data.code === '200') {
            this.isShowAdd = false
            this.$message.success('添加成功')
            this.sysConfigParamList()
          }
        })
      })
    },
    isShoweditDialog(scope) {
      // 编辑显示弹框
      var value = JSON.parse(JSON.stringify(scope.row))
      this.editForm = value
      this.isShowEdit = true
    },
    editClick() {
      // 编辑
      this.$refs.editRef.validate((valid) => {
        if (!valid) return
        addsysConfigParam(this.editForm).then((res) => {
          if (res.data.code === '200') {
            this.isShowEdit = false
            this.$message.success('编辑成功')
            this.sysConfigParamList()
          }
        })
      })
    },
  },
}
</script>
<style lang="scss" scoped>
</style>
